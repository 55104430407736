<template>
  <div>
    <v-row
      justify="start"
      class="mt-2"
      :class="
        !numFilters && !hiddenColumns.length
          ? buttons.length
            ? 'mb-n4'
            : 'mb-n12'
          : fixedHeader
          ? 'mb-n4'
          : 'mb-4'
      "
    >
      <v-col cols="12" :md="hasButtons ? 8 : 12">
        <h2 class="text-uppercase page-title mb-1">
          {{ pageTitle }}
          <slot name="titleSuffix" />
        </h2>
        <slot name="subheading" />
      </v-col>
      <v-col v-if="hasButtons" cols="12" md="4" class="d-flex flex-row-reverse">
        <div v-for="(btn, i) in buttons" :key="'btn-' + i">
          <v-btn
            :class="'mx-2 my-n2'"
            :to="btn.to"
            :color="i === 0 ? 'primary' : ''"
            @click="btn.click ? btn.click() : ''"
          >
            {{ btn.text }}
          </v-btn>
        </div>
        <slot name="buttons" />
      </v-col>
    </v-row>
    <v-row class="mb-n6">
      <v-col :cols="hideSearch ? 12 : 9">
        <v-row
          v-if="numFilters || hiddenColumns.length || enableCardView"
          justify="start"
          class="data-filter-wrapper"
          :class="hideSearch || numFilters < 3 ? '' : 'with-search'"
        >
          <v-col
            :cols="
              !numFilters ? 1 : numFilters === 1 ? 4 : numFilters === 2 ? 8 : 12
            "
            class="pt-2"
            :style="!showFilters ? `max-width: 50px !important` : ``"
          >
            <v-btn
              v-if="!hideFilterToggle && numFilters"
              fab
              text
              small
              class="d-block float-left ml-1 mr-n2"
              title="Show/hide filters"
              @click="showFilters = !showFilters"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
            <v-container
              v-if="showFilters && numFilters"
              class="d-block float-left filters-container"
              :class="hideSearch ? 'py-1' : 'pt-0'"
              :style="
                !hideFilterToggle
                  ? enableCardView || currentListType === 'card'
                    ? `max-width: calc(100% - 90px) !important`
                    : `max-width: calc(100% - 45px) !important`
                  : ``
              "
            >
              <v-row>
                <v-col
                  v-for="filter in filters"
                  :key="`filter-col-${filter}`"
                  :cols="filterCols"
                >
                  <slot :name="filter" />
                </v-col>
                <v-col
                  v-if="restaurants.length > 1 && restaurantFilterAvailable"
                  cols="filterCols"
                >
                  <v-select
                    v-model="restaurantID"
                    :label="restaurantID != null ? '' : 'All Restaurants'"
                    :items="restaurants"
                    item-value="id"
                    item-text="name"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    clearable
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              v-if="currentListType === 'cards'"
              fab
              text
              small
              class="d-block float-left ml-1 mr-n2 mb-2"
              title="Switch to table view"
              @click="currentListType = 'table'"
            >
              <v-icon>mdi-view-headline</v-icon>
            </v-btn>
            <v-btn
              v-else-if="enableCardView"
              fab
              text
              small
              class="d-block float-left ml-1 mr-n2 mb-2"
              title="Switch to card view"
              @click="currentListType = 'cards'"
            >
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-show="!hideSearch || hiddenColumns.length"
        class="align-self-end"
        cols="3"
      >
        <v-row
          justify="end"
          class="search-and-reset-section"
          :class="{ 'ml-n15': !hiddenColumns.length }"
        >
          <v-col v-if="hiddenColumns.length" cols="6" class="pa-0">
            <v-btn class="pa-5 mt-2" absolute text small @click="hiddenColumns = []">
              <v-icon class="mr-2">mdi-eye-refresh</v-icon>
              Reset
              <br />
              columns
            </v-btn>
          </v-col>
          <v-col
            v-if="!hideSearch"
            :cols="hiddenColumns.length ? 6 : 12"
            class="py-4 search-col"
            :class="searchFocused || search ? 'focused' : ''"
            style=""
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              background-color="#000"
              placeholder="Type keyword to filter"
              single-line
              hide-details
              dark
              shaped
              filled
              clearable
              @click:clear="search = ''"
              @focus="searchFocused = true"
              @blur="searchFocused = false"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card
      :class="`${currentListType}-card elevation-${
        currentListType === 'table' ? 1 : 0
      }`"
    >
      <v-row v-if="currentListType === 'cards'" class="mx-0">
        <v-col v-if="loadingData" class="d-flex ma-10 justify-center">
          <v-progress-circular
            indeterminate
            size="15"
            width="2"
            class="mr-2"
          ></v-progress-circular>
          Loading
        </v-col>
        <v-col v-else-if="items.length === 0" class="d-flex ma-10 justify-center">
          {{ noDataText }}
        </v-col>
        <v-col
          v-for="(item, i) in filteredSearchedItems"
          v-else
          :key="i"
          cols="12"
          md="6"
          lg="4"
          xl="3"
        >
          <v-card elevation="2" :dark="false">
            <slot name="card" :item="item" />
            <v-card-actions v-if="!customizedCardActions">
              <slot name="item.actions" :item="item" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-data-table
        v-else
        v-model="selectedItems"
        :show-select="showSelect"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, -1],
        }"
        :headers="formattedHeaders"
        :items="filteredItems"
        :sort-by="
          sortBy
            ? sortBy
            : headers.findIndex(header => header.value === 'date') !== -1
            ? 'date'
            : sortBy
        "
        :sort-desc="sortDesc"
        :search="search"
        :loading="loadingData"
        loading-text="Loading data, please wait..."
        :no-data-text="noDataText"
        :fixed-header="fixedHeader"
        :height="fixedHeader ? fixedHeaderHeight : undefined"
        :item-key="itemKey"
        class="page-data-table"
        :class="{ 'has-sticky-footer': typeof sumRowFunction === 'function' }"
      >
        <template
          v-for="header in formattedHeaders"
          v-slot:[`header.${header.value}`]
        >
          <v-tooltip
            v-if="header.description"
            :key="`${header.value}-header-tooltip`"
            bottom
            nudge-bottom="20"
            max-width="200px"
            color="primary"
            nudge-top="-20"
          >
            <template v-slot:activator="{ on }">
              <span
                :key="`${header.value}-header-text`"
                class="text-no-wrap"
                v-on="on"
              >
                {{ header.text }}
              </span>
            </template>
            <span>{{ header.description }}</span>
          </v-tooltip>
          <span v-else :key="`${header.value}-header-text`">
            {{ header.text }}
          </span>
          <a
            v-if="formattedHeaders.length > hiddenColumns.length"
            :key="`${header.value}-hide-btn`"
            class="hide-column-btn mx-1"
            @click="hideColumn(header.value)"
          >
            <v-icon small>mdi-eye-off</v-icon>
          </a>
        </template>
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
        >
          <slot :name="slot" v-bind="scope" />
        </template>
        <template
          v-if="!loadingData && typeof sumRowFunction === 'function'"
          v-slot:[`body.append`]
        >
          <tr>
            <td class="font-weight-bold" colspan="2">Totals</td>
            <td
              v-for="(header, index) in filteredHeaders"
              v-show="index > 1"
              :key="index"
              class="font-weight-bold"
            >
              <span>
                {{ sumRowFunction(header.value) }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "page-component",
  props: {
    listType: {
      type: String,
      required: false,
      default: "table",
    },
    pageTitle: {
      type: String,
      required: true,
    },
    restaurantFilterAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 25,
    },
    sortBy: {
      type: String,
      required: false,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemKey: {
      type: String,
      required: false,
      default: "id",
    },
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
    loadingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    noDataText: {
      type: String,
      required: false,
      default: "No data available",
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    enableCardView: {
      type: Boolean,
      required: false,
      default: false,
    },
    customizedCardActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFilterToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    sumRowFunction: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      restaurantID: null,
      showFilters: true,
      hiddenColumns: [],
      currentListType: this.listType,
      search: "",
      searchFocused: false,
      windowHeight: window.innerHeight,
    }
  },
  computed: {
    ...mapState(["restaurants"]),
    selectedItems: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    filteredItems() {
      let items = this.items
      if (this.restaurantFilterAvailable && this.restaurantID) {
        items = items.filter(item => {
          return (
            (item.restaurantId && item.restaurantId == this.restaurantID) ||
            (item.restaurantID && item.restaurantID == this.restaurantID) ||
            (item.restaurants &&
              item.restaurants.findIndex(r => r.id == this.restaurantID) !== -1) ||
            (item.restaurantData &&
              item.restaurantData.findIndex(
                r => r.restaurantId == this.restaurantID
              ) !== -1)
          )
        })
      }
      return items
    },
    filteredSearchedItems() {
      if (!this.search) return this.filteredItems
      return this.filteredItems.filter(item => {
        return Object.values(item).some(val =>
          val?.toString()?.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },
    formattedHeaders() {
      return this.filteredHeaders.map(header => {
        const formattedHeader = { ...header }
        formattedHeader.class = "text-no-wrap"
        if (header.value === "actions") {
          formattedHeader.cellClass = "text-no-wrap"
        }
        return formattedHeader
      })
    },
    filteredHeaders() {
      return this.headers.filter(
        header => !this.hiddenColumns.includes(header.value)
      )
    },
    hasButtons() {
      return this.$scopedSlots.buttons || this.buttons.length
    },
    numFilters() {
      return (
        this.filters.length +
        (this.restaurantFilterAvailable && this.restaurants.length > 1 ? 1 : 0)
      )
    },
    filters() {
      return Object.keys(this.$scopedSlots).filter(name => name.startsWith("filter"))
    },
    filterCols() {
      const len = this.numFilters
      switch (len) {
        case 1:
          return 12
        case 2:
          return 6
        case 4:
          return 3
        // case 5:
        // case 6:
        //   return 2
        default:
          return 4
      }
    },
    fixedHeaderHeight() {
      return this.windowHeight - 320
    },
  },
  mounted() {
    this.hiddenColumns = this.hideColumns
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize)
  },
  methods: {
    hideColumn(value) {
      this.hiddenColumns.push(value)
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
  },
}
</script>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.data-filter-wrapper {
  width: 100%;
  z-index: 1;
}
.hide-column-btn {
  opacity: 0;
}
.v-data-table-header th:hover .hide-column-btn {
  opacity: 1;
}
</style>

<style lang="scss">
.data-filter-wrapper > .col {
  background: var(--v-light-background-base);
  padding: 5px 0;
  margin: 5px -12px 11px 12px;
  max-width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border: solid 1px var(--v-light-border-base);
}
.filters-container {
  padding-bottom: 1.6em;
  .col {
    padding-bottom: 0 !important;
  }
}
.data-filter-wrapper.with-search > .col {
  padding-right: 30px;
}
.search-and-reset-section .col {
  transition: all 0.8s ease-in-out;
}
.search-col {
  &.focused {
    max-width: 100%;
  }
  &:not(.focused) {
    max-width: 75px;
  }
  .v-text-field {
    .v-input__slot {
      .v-input__append-inner {
        display: none;
      }
      .v-input__append-inner ~ .v-input__append-inner {
        display: flex;
      }
      background: var(--v-primary-base) !important;
      padding: 10px;
      input {
        caret-color: var(--v-text-on-primary-bg-base);
      }
      .v-icon::before {
        color: var(--v-text-on-primary-bg-base);
      }
    }
  }
}
.data-filter-wrapper .v-input:not(.v-input--checkbox) {
  background: var(--v-background-base);
}
.data-filter-wrapper .v-input fieldset {
  border-color: var(--v-primary-base);
}
.page-data-table.has-sticky-footer tbody tr:last-child td {
  position: sticky;
  bottom: 0;
  color: var(--v-text-on-primary-bg-base);
  background-color: var(--v-primary-base);
}
.page-data-table thead th {
  position: relative;
  z-index: 2;
}
</style>
